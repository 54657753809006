@font-face {
  font-family: 'Roboto regular';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Roboto-Regular.ttf")
}
@font-face {
  font-family: 'Roboto medium';
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Roboto-Medium.ttf")
}
@font-face {
  font-family: 'Roboto bold';
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Roboto-Bold.ttf")
}
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto regular';
}
body{
  background-color: #FFF;
}
button{
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}